import React, { useRef, useEffect } from 'react'
import { Password } from 'primereact/password'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { AutoComplete } from 'primereact/autocomplete'
import { MultiSelect } from 'primereact/multiselect'
import { locale, addLocale } from 'primereact/api'
import { Text, Flex } from '..'

// Estrae lo stile dal componente e ritorna quelli necessari al contenitore
const extractStyle = (props) => {
  const { width, height, marginTop, marginBottom, marginLeft, marginRight, gridArea, ...otherStyles } = props.style
  props.style = otherStyles
  return { width, height, marginTop, marginBottom, marginLeft, marginRight, gridArea }
}

const monthNavigatorTemplate = (e) =>
  <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />

const yearNavigatorTemplate = (e) =>
  <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className='p-ml-2' style={{ lineHeight: 1 }} />

export const Input = ({
  setRef, setValue, useState, onChange, onEnter,
  autofocus, password, time, date, label, multiline, dropdown, inputAutoComplete,
  autoComplete, error, autocomplete, dropdownAutoComplete, multiselect, number, ...props
}) => {
  const inputRef = useRef(null)
  setRef && setRef(inputRef)
  props.ref = inputRef
  useEffect(() => {
    autofocus && setTimeout(() => {
      if (!inputRef || !inputRef.current) return false
      inputRef.current.element && inputRef.current.element.focus()
      inputRef.current.inputEl && inputRef.current.inputEl.focus()
    }, 500)
  }, [autofocus])

  if (onChange && !props.id) throw new Error('Missing ID for OnChange')

  props.value === undefined && (props.value = '')
  useState && useState.length === 2 && (props.value = useState[0])

  useState && useState.length === 2 && (props.onChange = (e) => useState[1](e.target.value))
  setValue && (props.onChange = (e) => setValue(e.target.value))
  onChange && (props.onChange = (e) => onChange({ [e.target.id]: e.target.value }))
  onEnter && (props.onKeyDown = (key) => key.keyCode === 13 && onEnter())
  useState && onChange && (props.onChange = (e) => {
    useState[1](e.target.value)
    onChange(e.target.value)
  })

  props.autoComplete = autoComplete || 'off'

  props.style = {
    // border: 'none',
    borderRadius: 20,
    paddingLeft: 12,
    width: '100%',
    ...(props.id ? { gridArea: props.id } : {}),
    ...props.style
  }

  error && (props.className = 'invalid-input')

  /* if (password) {
    props.autoComplete = 'current-password'
    props.placeholder = 'Password'
    props.feedback = false
    return <Password {...props} />
  } */

  if (date) {
    const containerStyle = extractStyle(props)
    props.yearRange = props.yearRange || '2010:2030'
    props.showIcon = props.showIcon || false
    // props.locale = dateLocale.it
    props.monthNavigatorTemplate = monthNavigatorTemplate
    props.yearNavigatorTemplate = yearNavigatorTemplate
    props.dateFormat = 'dd/mm/yy'
    props.readOnlyInput = true
    props.inputStyle = {
      paddingLeft: 12,
      borderRadius: 20,
      border: 'none',
      cursor: 'pointer',
      backgroundColor: 'transparent'
    }

    return (
      <Flex row js className='inputTxt' style={{ paddingLeft: 12, borderRadius: 20, cursor: 'pointer', ...containerStyle }}>
        {label ? <Text value={label} style={{ whiteSpace: 'nowrap' }} /> : null}
        <Calendar {...props} />
      </Flex>
    )
  }

  if (time) {
    return (
      <div
        style={{
          marginLeft: -10,
          position: 'relative',
          ...props.style,
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20
        }}
      >
        <Calendar
          inputStyle={{
            paddingLeft: label ? label.length * 8 + 12 : 12,
            borderRadius: 20
          }}
          showIcon
          value={props?.value !== 'Invalid Date' ? props?.value : ''}
          onChange={(e) => {
            onChange({ [e.target.id]: e.target.value })
          }}
          timeOnly
          showTime
          hourFormat='24'
          className={`${error ? 'invalid-input' : ''}`}
          {...props}
        />
        {label && (
          <Text style={{ position: 'absolute', left: 20, top: 6 }} color='rgb(120, 120, 120)' value={label} />
        )}
      </div>
    )
  }

  if (password) {
    props.style.paddingLeft = 0
    return (
      <Password
        onKeyDown={(key) => key.keyCode === 13 && onEnter && onEnter()}
        feedback={false}
        inputStyle={{ width: '100%', borderRadius: 20 }}
        {...props}
        onChange={(e) => onChange(e.target.value)}
      />
    )
  }

  if (multiline) {
    props.autoResize === undefined && (props.autoResize = true)
    props.style.height = '100%'
    props.style.padding = 15
    return <InputTextarea {...props} />
  }

  if (dropdown) {
    if (label) {
      const containerStyle = extractStyle(props)
      props.style.width = props.style.textWidth || '50%'
      props.style.flexGrow = 1
      props.style.marginLeft = 5
      return (
        <Flex row js className='inputTxt' style={{ paddingLeft: 12, borderRadius: 20, ...containerStyle }}>
          <Text value={label} />
          <Dropdown {...props} />
        </Flex>
      )
    }
    return <Dropdown {...props} />
  }
  if (inputAutoComplete) {
    const containerStyle = extractStyle(props)

    const { style, ...extraProps } = props

    return (
      <Flex fw row js className='inputTxt' style={{ paddingLeft: 12, borderRadius: 20, ...containerStyle }}>
        <Text value={label} />
        <AutoComplete
          inputStyle={{ width: '100%', borderRadius: 20 }}
          style={{ width: '100%' }}
          onSelect={({ value }) => onChange({ [props.id]: value[props.id] })}
          {...extraProps}
        />
      </Flex>
    )
  }

  if (number) {
    if (label) {
      const containerStyle = extractStyle(props)
      props.style.width = props.style.textWidth || '50%'
      props.style.flexGrow = 1
      props.style.marginLeft = 5
      return (
        <Flex row js className='inputTxt' style={{ paddingLeft: 12, borderRadius: 20, ...containerStyle }}>
          <Text value={label} />
          <InputText
            keyfilter='int'
            {...props}
          />
        </Flex>
      )
    }
    return (
      <InputText
        keyfilter='int'
        {...props}
      />
    )
  }

  if (label) {
    const containerStyle = extractStyle(props)
    props.style.width = props.style.textWidth || '50%'
    props.style.flexGrow = 1
    props.style.marginLeft = 5
    return (
      <Flex row js className='inputTxt' style={{ paddingLeft: 12, borderRadius: 20, ...containerStyle }}>
        <Text value={label} />
        <InputText {...props} />
      </Flex>
    )
  }

  if (multiselect) {
    return (
      <MultiSelect
        {...props} className='w-full'
      />
    )
  }

  return <InputText {...props} />
}

addLocale('it', {
  firstDayOfWeek: 1,
  dayNames: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
  dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
  dayNamesMin: ['D', 'L', 'Ma', 'Me', 'G', 'V', 'S'],
  monthNames: [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
  ],
  monthNamesShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic']
})
locale('it')

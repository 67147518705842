import React, { useRef, useState } from 'react'
import { Flex } from '../Common/Flex'
import { Text } from '../Common/Text'
import { Composer } from './Composer'
import { Button } from '../Common/Button'
import { Card } from '../Common/Card'
import { JsonViewer } from '../Common/JsonViewer'
import { Modal } from '../Common/Modal'
import { AudioControl } from './AudioControl'
/* global FileReader */
const dateOptionsShort = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' }

const ChatWrapper = ({ children }) => (
  <Card style={{ width: '100%' }}>
    <Flex fw style={{ justifyContent: 'space-between', height: '95%' }}>
      {children}
    </Flex>
  </Card>
)

const Writer = ({ sendMessage, composerStyle }) => {
  const [currentMessage, setCurrentMessage] = useState('')
  const [sending, setSending] = useState(false)
  const [files, setFiles] = useState([])
  const refBtn = useRef(null)

  const onSend = async () => {
    if (!currentMessage?.length && !files?.length) return // controllo che non sia vuoto il message, al fine di non fare invio inutilmente.
    setSending(true)
    setCurrentMessage('')
    const attachments = await Promise.all(files.map(async (file) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      return new Promise((resolve) => {
        reader.onload = () => resolve(reader.result)
      })
    }))
    setFiles([])
    sendMessage(currentMessage, attachments)
    setSending(false)
  }

  return (
    <Flex row fw>
      <input type='file' id='file' name='file' onChange={(e) => setFiles([...files, ...e.target.files])} style={{ display: 'none' }} ref={refBtn} multiple />
      <Button label='Allega' onClick={() => refBtn.current.click()} style={{ marginRight: 10 }} />
      <Flex as js>
        {files.map((file, index) => (
          <Flex key={index} row style={{ margin: 5 }}>
            <Text value={file.name} style={{ fontSize: 12, marginRight: 10 }} />
            <Button icon='close' round onClick={() => setFiles(files.filter((_, i) => i !== index))} />
          </Flex>
        ))}
      </Flex>
      <Composer update={setCurrentMessage} send={onSend} currentMessage={currentMessage} sending={sending} composerStyle={composerStyle} />
    </Flex>
  )
}

const ChatMessages = ({ messages, jsonVisualized }) => (
  <Flex fw js style={{ width: '100%', overflowY: 'auto', maxHeight: '90%', marginBottom: 20 }}>
    {messages.map((message, index) => (<ChatMessage key={index} message={message} jsonVisualized={jsonVisualized} />))}
  </Flex>
)

const ChatMessage = ({ message, jsonVisualized = true }) => {
  const { jsonPayload, message: messageText = '', user, createdAt } = message
  const [showImg, setShowImg] = useState(false)
  const [media, setMedia] = useState(null)

  return (
    <Flex fw ae={user !== 'Nora'} as={user === 'Nora'}>
      <div style={{
        backgroundColor: user === 'Nora' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0,0,0, 0.2)',
        borderRadius: 10,
        width: '95%',
        padding: 10,
        marginTop: 15,
        border: '1px solid #dadada',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word'
      }}
      >
        <Flex fw as row jb>
          <Text value={user} style={{ fontSize: 12 }} />
          <Text value={createdAt?.toLocaleString('it-IT', dateOptionsShort)} style={{ fontSize: 12 }} />
        </Flex>
        <hr style={{ width: '100%', margin: '5px 0' }} />
        {messageText.match(/<\/?[^>]+(>|$)/g)
          ? messageText?.replace(/<\/?[^>]+(>|$)/g, '')
            ?.replace(/&nbsp;/g, ' ')
            ?.split('\n')
            ?.map((p) => p?.trim())
            ?.filter(Boolean)
            ?.map((p = '\n', index) => (<Text key={index} value={p} size={15} style={{ wordBreak: 'break-word' }} />))
          : <Text value={messageText} size={15} style={{ wordBreak: 'break-word' }} />}
        <Flex fw js row style={{ marginTop: 10 }}>
          {message.attachments?.filter(({ type }) => type.includes('image')).map((media, index) => (
            <Button
              icon='image' label={'Immagine ' + (index + 1)} onClick={() => { setMedia(media); setShowImg(true) }}
              key={index} style={{ marginRight: 10 }}
            />
          ))}
          {message.attachments?.filter(({ type }) => type.includes('audio')).map((media, index) => (
            <AudioControl key={index} url={media.url} label={'Audio ' + (index + 1)} style={{ marginRight: 10 }} />
          ))}
        </Flex>
        <Modal
          style={{ maxWidth: '80%', maxHeight: '80%', borderRadius: 10 }}
          contentStyle={{ padding: 0, borderRadius: 10 }}
          visible={showImg} onHide={() => setShowImg(false)}
        >
          <Button icon='close' round onClick={() => setShowImg(false)} style={{ position: 'absolute', top: 10, right: 10 }} />
          {media?.type?.includes('image') && <img src={media?.url} style={{ width: '100%' }} />}
          {media?.type?.includes('audio') &&
            <Flex style={{ minWidth: 500, height: 100 }}>
              <audio controls src={media?.url} style={{ width: '80%' }} ref={(audio) => { audio?.setAttribute('controlsList', 'nodownload') }} />
            </Flex>}
        </Modal>
        {jsonVisualized && !!jsonPayload && (<VisualizeJSONPayload jsonPayload={jsonPayload} />)}
      </div>
    </Flex>
  )
}

const VisualizeJSONPayload = ({ jsonPayload }) => {
  const [openJSON, setOpenJSON] = useState(window?._env_?.ENVIRONMENT === 'development')
  return (
    <>
      {!openJSON && <Button label='Leggi JSON' onClick={() => setOpenJSON(true)} />}
      {openJSON && (
        <>
          <JsonViewer name='Risultato Elaborazione' src={jsonPayload} />
          <Button label='Chiudi' onClick={() => setOpenJSON(false)} />
        </>
      )}
    </>
  )
}

export { ChatWrapper, Writer, ChatMessages, ChatMessage }

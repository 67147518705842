import { useState } from 'react'
import { FC } from '../../../Services'
import _ from 'lodash'
import { Flex, PanelText } from '../../../Components'
import { AutoComplete } from 'primereact/autocomplete'
import { InputText } from 'primereact/inputtext'
import { alert } from '../Functions'

export const SearchByGroup = ({ codiceGruppo: [codiceGruppo, setCodiceGruppo], descrizioneGruppo: [descrizioneGruppo, setDescrizioneGruppo], disabled }) => {
  const [suggestionsDescrizione, setSuggestionsDescrizione] = useState([])

  const onCodiceChange = async (codiceGruppo) => {
    setCodiceGruppo(codiceGruppo)
    if (codiceGruppo.length < 2) {
      setDescrizioneGruppo('')
      return
    }
    const group = await FC.service('info').get('getGroups', { query: { codice: codiceGruppo } })
    if (!group.success) {
      alert('error', 'Errore nella ricerca del gruppo', group.errorMessage)
      return
    }
    if (!group.found) {
      setDescrizioneGruppo('')
      return
    }
    setDescrizioneGruppo(group.groups[0].descrizione)
  }

  const debounceSearchDescrizione = _.debounce(async (event) => {
    const value = event.query.trim()
    if (value.length < 1) {
      setCodiceGruppo('')
      return setSuggestionsDescrizione([])
    }
    const group = await FC.service('info').get('getGroups', { query: { descrizione: value } })
    const descrizioni = group.groups.map(el => el.descrizione)
    setSuggestionsDescrizione(descrizioni)
    if (descrizioni.length !== 1) {
      setCodiceGruppo('')
      return
    }
    setCodiceGruppo(group.groups[0].codice)
  }, 500)

  const selectDescrizioneGruppo = async (value) => {
    const group = await FC.service('info').get('getGroups', { query: { descrizione: value } })
    setCodiceGruppo(group.groups[0].codice)
  }

  return (
    <Flex je ae wrap style={{ rowGap: 15, width: '49%' }}>
      <Flex js as style={{ width: '95%' }}>
        <PanelText value='Ricerca gruppo' header />
      </Flex>
      <InputText
        value={codiceGruppo || ''}
        style={{ width: '95%', borderRadius: 13 }}
        maxLength={3}
        onChange={(e) => onCodiceChange(e.target.value)}
        label='Codice Gruppo'
        id='codiceGruppo'
        placeholder='Codice'
        disabled={disabled}
      />
      <Flex je ae fw>
        <AutoComplete
          disabled={disabled}
          inputId='descrizioneGruppo' placeholder='Descrizione'
          inputStyle={{ width: '100%', borderRadius: 13 }}
          style={{ width: '95%' }} value={descrizioneGruppo || ''}
          suggestions={suggestionsDescrizione} completeMethod={debounceSearchDescrizione}
          onChange={({ value }) => setDescrizioneGruppo(value)}
          onSelect={({ value }) => selectDescrizioneGruppo(value)}
        />
      </Flex>
    </Flex>
  )
}

import React, { useState, useEffect } from 'react'
import { useCQuery } from '../../../Services'
import { Button, Flex, Input, Spinner, Text } from '../../../Components'
import { useNavigate, useParams } from 'react-router-dom'
import { searchInView } from './Functions'
import { JsonView } from './JsonView'
import { FormView } from './FormView'
import { reexecuteUnit } from '../Editor/Functions'

export const ProcessCompare = () => {
  const { processId } = useParams()
  const navigate = useNavigate()
  const { data: processSteps = [], isSuccess } = useCQuery(['processSteps', processId])
  const [stateIndex, setStateIndex] = useState(0) // used to choose the unit to compare
  const [searchString, setSearchString] = useState('') // used to search for a word
  const [numberSearch, setNumberSearch] = useState(0) // used to scroll to the next occurence, when the same word is searched multiple times
  const [totalSearch, setTotalSearch] = useState(0)
  const [jsonView, setJsonView] = useState(true)

  const scrollIntoView = () => setTimeout(() => setTotalSearch(searchInView(searchString, numberSearch) || 0), 10)

  useEffect(() => {
    setNumberSearch(0)
    scrollIntoView(searchString, numberSearch)
  }, [searchString, stateIndex])

  useEffect(() => { scrollIntoView(searchString, numberSearch) }, [numberSearch])

  if (!isSuccess) return <Spinner />

  return (
    <Flex fw>
      <Flex js row fw>
        <Flex wrap row as js style={{ width: '35%', gap: 10 }}>
          <Button round tooltip='Torna indietro' icon='left' onClick={() => navigate('/process/' + processId)} />
          <Button round tooltip={jsonView ? 'Vista Form' : 'Vista json'} icon='choice' onClick={() => setJsonView(!jsonView)} />
          <Button
            round tooltip='Riesegui unit' icon='play'
            onClick={() => reexecuteUnit(processSteps[stateIndex]?.unit, processSteps[stateIndex]?.initialState)}
          />
        </Flex>
        <Flex row style={{ width: '30%' }}>
          <Button allowMultipleClick icon='left' round onClick={() => setStateIndex(stateIndex - 1)} disabled={stateIndex === 0} />
          {/*      <Text center value={`Unit: ${processSteps[stateIndex]?.unit}`} style={{ marginLeft: 20, marginRight: 20, minWidth: 340 }} /> */}
          <Input
            dropdown
            id='unit'
            value={processSteps[stateIndex]?.unit}
            options={processSteps.map(({ unit }) => unit)}
            onChange={({ unit: value }) => setStateIndex(processSteps.findIndex(({ unit }) => unit === value))}
            style={{ marginLeft: 20, marginRight: 20, width: 340 }}
          />
          <Button allowMultipleClick icon='right' round onClick={() => setStateIndex(stateIndex + 1)} disabled={stateIndex === processSteps.length - 1} />
        </Flex>
        <Flex row style={{ width: '35%' }}>
          {jsonView &&
            <>
              <Input
                id='search' placeholder='Cerca...' value={searchString}
                onChange={({ search }) => setSearchString(search)}
                onEnter={() => setNumberSearch((numberSearch + 1) % totalSearch)}
              />
              {!!totalSearch &&
                <Flex row>
                  <Text center value={`${numberSearch + 1}/${totalSearch}`} style={{ width: 70 }} />
                  <Button allowMultipleClick icon='up' round onClick={() => setNumberSearch((numberSearch - 1 + totalSearch) % totalSearch)} disabled={totalSearch === 0} />
                  <Button allowMultipleClick icon='down' round onClick={() => setNumberSearch((numberSearch + 1) % totalSearch)} disabled={totalSearch === 0} />
                </Flex>}
            </>}
        </Flex>
      </Flex>
      {jsonView
        ? <JsonView stateIndex={stateIndex} />
        : <FormView stateIndex={stateIndex} />}
    </Flex>
  )
}

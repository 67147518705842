import React from 'react'
import { Button, Flex, Icon, PanelText } from '../../../Components'
import { Divider } from 'primereact/divider'
import { FC } from '../../../Services'
import { InputText } from 'primereact/inputtext'
import { alert } from '../Functions'
import { SearchByClient } from '../Search/SearchByClient'
import { SearchByGroup } from '../Search/SearchByGroup'
import { useNavigate } from 'react-router-dom'
import { Checkbox } from 'primereact/checkbox'

const HeaderSettings = ({
  presetId, clientCode: [clientCode, setClientCode],
  selectedClient: [selectedClient, setSelectedClient], save,
  codiceGruppo: [codiceGruppo, setCodiceGruppo],
  descrizioneGruppo: [descrizioneGruppo, setDescrizioneGruppo],
  group: [group, setGroup],
  titolo: [titolo, setTitolo],
  ragioneSociale: [ragioneSociale, setRagioneSociale],
  defaultPreset: [defaultPreset, setDefaultPreset],
  initialDefaultPreset: [initialDefaultPreset, setInitialDefaultPreset]
}) => {
  const navigate = useNavigate()
  const handleSearch = async () => {
    // Check if all clientCode values are empty strings.
    const hasEmptyClientCodeValues = Object.values(clientCode).some(v => v === '')
    if (hasEmptyClientCodeValues) {
      // Early return on error conditions
      if (!codiceGruppo && !descrizioneGruppo) {
        return alert('error', 'Errore', 'Inserire almeno un parametro di ricerca')
      }

      const groupSearch = await FC.service('info').get('getGroups', { query: { codice: codiceGruppo } })

      // Check various error conditions related to groupSearch
      if (!groupSearch.success && codiceGruppo !== 'DEFAULT') {
        return alert('error', 'Errore nella ricerca del gruppo', groupSearch.errorMessage)
      }
      if (!groupSearch.found && codiceGruppo !== 'DEFAULT') {
        return alert('error', 'Gruppo non trovato', '')
      }
      const presetExists = await checkPresetEsistente('gruppo')
      if (presetExists) {
        setGroup()
        alert('warn', 'Preset esistente', 'Questo gruppo ha già una configurazione salvata')
        return navigate('/community/panel/' + presetExists.id)
      }
      // If none of the error conditions apply, the function exits here
      return setGroup(groupSearch)
    }

    setSelectedClient()

    // Destructure clientCode object for easier access to its properties.
    const { mastro, gruppo, conto, sottoConto } = clientCode
    // Use template literals to format stringCode.
    const stringCode = `${mastro.padStart(2, '0')}${gruppo.padStart(2, '0')}${conto.padStart(2, '0')}${sottoConto.padStart(6, ' ')}`

    const { data: [client] = [], ok } = await FC.service('info').get('searchClient', { query: { codCliente12: stringCode } })

    if (!ok || !client) return alert('error', 'Errore', 'Cliente non trovato')
    // Using short-circuit evaluation to conditionally execute code.
    ok && client && setSelectedClient({
      codCliente12: client?.datiGeneraliCliente?.codCliente12,
      ragioneSociale: client?.datiGeneraliCliente?.ragioneSociale
    })

    setRagioneSociale(client?.datiGeneraliCliente?.ragioneSociale)

    // Check if a preset exists for the client.
    const presetExists = await checkPresetEsistente('cliente', client?.datiGeneraliCliente?.codCliente12)
    if (presetExists) {
      setSelectedClient() // Unset the selected client.
      alert('warn', 'Preset esistente', 'Questo cliente ha già una configurazione salvata')
      return navigate('/community/panel/' + presetExists.id)
    }
  }

  const checkPresetEsistente = async (type, codCliente) => {
    const queryParam = type === 'gruppo' ? { codiceGruppo } : { codiceCliente: codCliente }
    const [preset = false] = await FC.service('communityPreset').find({ query: queryParam })

    // Return the boolean result of the condition directly.
    return preset
  }

  const canSearch = !selectedClient && !group && presetId === 'new'

  return (
    <Flex fw>
      <Flex row fw wrap style={{ gap: 15 }}>
        <div
          style={{
            width: 53,
            height: 48,
            borderRadius: 10,
            border: '1px solid #E4E7EC',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Icon name='settings' size={26} />
        </div>
        <Flex as fh>
          <PanelText value='Impostazioni' header />
          <PanelText value='Gestisci Nora AI' />
        </Flex>
        <Flex row ae je style={{ marginLeft: 'auto' }}>
          <Flex fw row wrap style={{ width: 250, marginRight: 20, alignItems: 'center', gap: 10 }}>
            <Checkbox checked={defaultPreset} onChange={e => setDefaultPreset(e.checked)} disabled={initialDefaultPreset} />
            <Flex as fh>
              <PanelText value='Usa preset come default' header />
            </Flex>
          </Flex>
          <Button icon='back' label='Indietro' style={{ width: 200, marginRight: 20 }} onClick={() => navigate('/community/search')} />
          {(presetId !== 'new' || (selectedClient || group)) &&
            (<Button icon='check' label='Salva' style={{ width: 200 }} onClick={save} />)}
        </Flex>
      </Flex>
      <Flex fw as>
        <Flex fw js wrap style={{ marginTop: 40 }}>
          <Flex fw as row wrap style={{ gap: 10 }}>
            <SearchByClient clientCode={[clientCode, setClientCode]} selectedClient={[selectedClient, setSelectedClient]} ragioneSociale={[ragioneSociale, setRagioneSociale]} disabled={!canSearch} />
            <SearchByGroup codiceGruppo={[codiceGruppo, setCodiceGruppo]} descrizioneGruppo={[descrizioneGruppo, setDescrizioneGruppo]} disabled={!canSearch} />
          </Flex>
        </Flex>
        {canSearch && <Button icon='lens' label='Cerca' style={{ width: 120, marginTop: 20 }} onClick={handleSearch} />}
      </Flex>
      {(presetId !== 'new' || (selectedClient || group)) && (
        <>
          <Divider />
          <Flex fw as js wrap style={{ gap: 10, marginTop: 10 }}>
            <PanelText value='Titolo Preset' header />
            <InputText value={titolo} onChange={({ target: { value } }) => setTitolo(value)} style={{ borderRadius: 13, width: '50%' }} />
          </Flex>
          <Divider />
        </>
      )}
    </Flex>
  )
}

export default HeaderSettings

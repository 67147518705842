export const getDiffStatus = ({ id }, diff, count = false) =>
  diff?.find((el) => el?.id === id && (count === false || count === el.count))?.diffStatus || ''

export const setErrors = (name, lastname, email, setNameError, setLastnameError, setEmailError) => {
  setNameError(name)
  setLastnameError(lastname)
  setEmailError(email)
}

export const validateEmail = (email) => {
  const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

  // Controlla se l'email ha almeno 3 caratteri e rispetta il formato dell'espressione regolare
  if (email.length >= 3 && regex.test(email)) return true
  else return false
}

export const alert = (message, type) => {
  window.growl.show({ severity: type, summary: type === 'error' ? 'Errore' : 'Successo', detail: message })
}

export const validateInput = ({ nome, cognome, mail }, { setNome, setCognome, setMail }, { setNameError, setLastnameError, setEmailError }) => {
  let isValid = true
  let errors = { nome: false, cognome: false, mail: false }

  if (!nome && !cognome && !mail) {
    isValid = false
    errors = { nome: true, cognome: true, mail: true }
  } else {
    if (nome && !cognome) {
      isValid = false
      errors.cognome = true
    }

    if (cognome && !nome) {
      isValid = false
      errors.nome = true
    }

    if (nome && nome.length < 3) {
      isValid = false
      errors.nome = true
      setNome('')
    }

    if (cognome && cognome.length < 3) {
      isValid = false
      errors.cognome = true
      setCognome('')
    }

    if (mail && !validateEmail(mail)) {
      isValid = false
      errors.mail = true
      setMail('')
    }
  }

  // Set all errors at once
  setNameError(errors.nome)
  setLastnameError(errors.cognome)
  setEmailError(errors.mail)

  if (!isValid) {
    // Construct error message based on the errors object
    alert(constructErrorMessage(errors), 'error')
  }

  return isValid
}

const constructErrorMessage = (errors) => {
  if (errors.nome && errors.cognome && errors.mail) {
    return 'Inserisci nome e cognome oppure email'
  }
  if (errors.nome && !errors.cognome) {
    return 'Inserisci anche il cognome'
  }
  if (!errors.nome && errors.cognome) {
    return 'Inserisci anche il nome'
  }
  if (errors.nome) {
    return 'Inserisci almeno 3 caratteri per il nome'
  }
  if (errors.cognome) {
    return 'Inserisci almeno 3 caratteri per il cognome'
  }
  if (errors.mail) {
    return 'Inserisci un\'email valida'
  }
}

import React from 'react'
import { FilterElement } from './FilterElement'
import { Flex } from '../../../Components'
import { Divider } from 'primereact/divider'
import { services } from '../Functions'

const FilterSections = (props) => (
  <Flex js as fw>
    {services.map(({ id, ...service }, index) => (
      <Flex key={index} fw>
        <Flex js as row fw>
          <FilterElement
            selectedFilters={props?.[id]?.selectedFilters}
            options={props?.[id]?.optionsFilters}
            service={service}
            inputValues={props?.[id]?.inputValues}
            inputRimborso={props?.[id]?.inputRimborso}
            inputNoRimborso={props?.[id]?.inputNoRimborso}
            radius={props?.[id]?.radius}
            checkAutomaticHotel={props?.[id]?.checkAutomaticHotel}
            logicaCameraNonInPenale={props?.[id]?.logicaCameraNonInPenale}
            soloCamereNonInPenale={props?.[id]?.soloCamereNonInPenale}
            tolleranzaGiorniPenale={props?.[id]?.tolleranzaGiorniPenale}
            servicePriority={props?.[id]?.servicePriority}
            flagAbilitazione={props?.[id]?.flagAbilitazione}
          />
        </Flex>
        {index < services?.length - 1 && <Divider />}
      </Flex>)
    )}
  </Flex>
)

export default FilterSections

import { Button } from '../Common/Button'
import { Flex } from '../Common/Flex'
import { Spinner } from '../Common/Spinner'
import { TextareaAuto } from '../Common/TextareaAuto'

const handleKeypress = (send) => (e) => {
  if (e.code === 'Enter' && !e.shiftKey) {
    e.preventDefault()
    send()
  }
}

export const Composer = ({ update, send, currentMessage, sending, composerStyle }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '80%',
        paddingRight: 40,
        ...composerStyle
      }}
    >
      <TextareaAuto
        placeholder='Scrivi un messaggio (Enter per inviare, Maiusc + Enter per andare a capo)'
        style={{ paddingTop: 12, minHeight: 45 }}
        value={currentMessage}
        autoFocus
        onChange={(e) => update(e.target.value)}
        onKeyPress={handleKeypress(send)}
      />
      {sending
        ? <Flex style={{ marginLeft: 10, height: 44, width: 44 }}> <Spinner /></Flex>
        : <Button icon='send' iconSize={36} onClick={send} style={{ marginLeft: 10, height: 44, width: 44, borderRadius: 22 }} />}
    </div>
  )
}

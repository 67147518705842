import React, { useEffect, useState } from 'react'
import { Button, ConfirmModal, Flex, Text } from '../../../Components'
import { useNavigate } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FC, invalidateQuery, useCQuery } from '../../../Services'
import { columnProps } from '../../Prompts/Functions'
import { Dropdown } from 'primereact/dropdown'

export function CommunityPresetsSearch () {
  const { data: communityPresets = [], isSuccess } = useCQuery(['communityPresets'])
  const [communityPresetsTable, setCommunityPresetsTable] = useState([])
  const [defaultValues] = useState(['Sì', 'No'])
  const navigate = useNavigate([])

  useEffect(() => {
    isSuccess && setCommunityPresetsTable(communityPresets.map((r) => ({ ...r, createdAt: new Date(r.createdAt).toLocaleString(), defaultPreset: r.defaultPreset ? 'Sì' : 'No' })))
  }, [communityPresets, isSuccess])

  const deleteOperation = async (presetId) => {
    const { defaultPreset = false } = communityPresets.find(({ id }) => id === presetId) || {}
    if (defaultPreset) {
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Impossibile eliminare il preset di default' })
      return false
    }
    const res = await FC.service('communityPreset').remove(presetId)
    invalidateQuery(['communityPresets'])
    window.growl.show({ severity: 'success', summary: 'Success', detail: 'Eliminazione effettuata' })
    return res
  }

  const BtnActionModify = ({ id }) => {
    const [modalVisible, setModalVisible] = useState(false)
    return (
      <Flex width={100} jb row>
        <ConfirmModal header='Cancellazione Servizio' label='Vuoi cancellare questo servizio?' onConfirm={() => deleteOperation(id)} visible={[modalVisible, setModalVisible]} />
        <Button round icon='edit' onClick={() => navigate('/community/panel/' + id)} />
        <Button style={{ marginLeft: 20 }} round tooltip='Elimina' icon='bin' onClick={() => setModalVisible(true)} />
      </Flex>
    )
  }

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown value={options.value} options={defaultValues} onChange={(e) => options.filterApplyCallback(e.value)} placeholder='Seleziona un valore' style={{ textAlign: 'center', borderRadius: 20, marginTop: 10, backgroundColor: 'var(--table-thead-bg)', width: '100%', height: '100%', fontSize: '1rem' }} />
    )
  }

  return (
    <Flex fw fh js>
      <Text value='Lista Community Preset' center size={20} bold />
      <Flex fw row je ae>
        <Button label='Crea Nuovo' icon='plus' style={{ marginRight: 10 }} onClick={() => navigate('/community/panel/new')} />
      </Flex>
      <DataTable
        style={{ width: '100%' }} filterDisplay='row' sortField='createdAt' sortOrder={-1} className='TableSignatures'
        value={communityPresetsTable} emptyMessage='Nessun preset trovato' responsiveLayout='scroll' paginator rows={10}
      >
        <Column field='id' {...columnProps('Id')} />
        <Column field='titolo' {...columnProps('Titolo')} />
        <Column field='codiceCliente' {...columnProps('Codice Cliente')} />
        <Column field='codiceGruppo' {...columnProps('Codice Gruppo')} />
        <Column field='descrizione' {...columnProps('Descrizione')} body={({ descrizione = '', ragioneSociale = '' }) => descrizione || ragioneSociale} />
        <Column field='defaultPreset' header='Default' headerStyle={{ visibility: 'hidden' }} filterMatchMode='contains' style={{ textAlign: 'center', border: 'none' }} showFilterMenu={false} showClearButton body={({ defaultPreset = false }) => defaultPreset ? 'Sì' : 'No'} filter filterElement={statusRowFilterTemplate} />
        <Column field='createdAt' {...columnProps('Data creazione')} />
        <Column style={{ border: 'none', width: 40, height: 15, textAlign: 'center' }} body={(props) => <BtnActionModify {...props} />} />
      </DataTable>
    </Flex>
  )
}

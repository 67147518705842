import React from 'react'
import { Flex, Icon, PanelText } from '../../../Components'
import { InputText } from 'primereact/inputtext'
import { Checkbox } from 'primereact/checkbox'
import { DraggableListServicePriority } from '../../../Components/Common/DraggableListServicePriority'
import { NewFilterDropdown } from './NewFilterDropdown'
import { DropdownElement } from './DropdownElement'

export const FilterElement = ({
  service: { icon, name, filters },
  selectedFilters: [selectedFilters, setSelectedFilters],
  inputValues: [inputValues, setInputValues],
  inputRimborso: [inputRimborso, setInputRimborso] = [],
  inputNoRimborso: [inputNoRimborso, setInputNoRimborso] = [],
  radius: [radius, setRadius],
  checkAutomaticHotel: [checkAutomaticHotel, setCheckAutomaticHotel] = [],
  logicaCameraNonInPenale: [logicaCameraNonInPenale, setLogicaCameraNonInPenale] = [],
  soloCamereNonInPenale: [soloCamereNonInPenale, setSoloCamereNonInPenale] = [],
  tolleranzaGiorniPenale: [tolleranzaGiorniPenale, setTolleranzaGiorniPenale] = [],
  options: [options, setOptions],
  servicePriority: [servicePriority, setServicePriority] = [],
  flagAbilitazione: [flagAbilitazione, setFlagAbilitazione] = []
}) => {
  return (
    <Flex fw js as wrap style={{ gap: 20 }}>
      <Flex row js as>
        <Flex
          style={{
            width: 53,
            height: 48,
            borderRadius: 10,
            border: '1px solid #E4E7EC',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            marginRight: 10
          }}
        >
          <Icon name={icon} />
        </Flex>
        <Flex as fh>
          <PanelText value={`Servizio ${name}:`} header />
          <PanelText value='Impostazione preventivi generati' />
        </Flex>
      </Flex>
      <Flex fw js as wrap style={{ gap: 15 }}>
        {(selectedFilters || [])?.map((filter, index) =>
          <DropdownElement
            name={name}
            key={index} index={index} value={filter} options={options}
            selectedFilters={[selectedFilters, setSelectedFilters]}
            inputValues={[inputValues, setInputValues]}
            inputRimborso={[inputRimborso, setInputRimborso]}
            inputNoRimborso={[inputNoRimborso, setInputNoRimborso]}
            flagAbilitazione={[flagAbilitazione, setFlagAbilitazione]}
          />
        )}
        <NewFilterDropdown name={name} options={options} selectedFilters={[selectedFilters, setSelectedFilters]} flagAbilitazione={[flagAbilitazione, setFlagAbilitazione]} />
      </Flex>
      {name === 'Hotel' && (
        <Flex fw as js wrap style={{ gap: 15 }}>
          <Flex row js fw wrap style={{ gap: 15 }}>
            <Checkbox onChange={e => setCheckAutomaticHotel(e.checked)} checked={checkAutomaticHotel} />
            <Flex as fh>
              <PanelText value='Hotel automatico' header />
              <PanelText value="Nel caso in cui la trasferta duri più giorni, viene aggiunto in automatico l'hotel." />
            </Flex>
          </Flex>
          <Flex row js fw wrap style={{ gap: 15 }}>
            <Checkbox checked={logicaCameraNonInPenale} onChange={e => setLogicaCameraNonInPenale(e.checked)} />
            <Flex as fh>
              <PanelText value='Logica camera non in penale' header />
              <PanelText value="Se l'hotel è soggetto a penale, verrà cercata la disponibilità della prima camera non soggetta a penale dello stesso hotel." />
              <PanelText value="Nel caso in cui questa non sia disponibile, verrà selezionata la camera 'best buy'." />
            </Flex>
          </Flex>
          {!!logicaCameraNonInPenale && (
            <Flex row js fw wrap style={{ gap: 15 }}>
              <Checkbox onChange={e => setSoloCamereNonInPenale(e.checked)} checked={soloCamereNonInPenale} />
              <Flex as fh>
                <PanelText value='Solo camere non in penale' header />
                <PanelText value="Nel caso in cui non si trovino camere non soggette a penale in un determinato hotel, passare all'hotel successivo e ripetere fino a 10 tentativi." />
              </Flex>
            </Flex>
          )}
          <Flex fw as js wrap style={{ gap: 10 }}>
            <PanelText value='Tolleranza Giorni Penale' header />
            <InputText value={tolleranzaGiorniPenale} onChange={({ target: { value } }) => setTolleranzaGiorniPenale(value)} style={{ borderRadius: 13 }} />
          </Flex>
        </Flex>
      )}
      {name === 'Treno' && (
        <Flex fw as js wrap style={{ gap: 15 }}>
          <PanelText value='Seleziona la priorità del servizio' header />
          <DraggableListServicePriority data={servicePriority} onUpdate={setServicePriority} />
        </Flex>
      )}
    </Flex>
  )
}

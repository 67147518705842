import { Dropdown } from 'primereact/dropdown'
import { Button, Flex } from '../../../Components'
import { InputNumber } from 'primereact/inputnumber'

export const DropdownElement = ({
  name,
  index: key, value, options,
  selectedFilters: [selectedFilters, setSelectedFilters],
  inputValues: [inputValues, setInputValues],
  inputRimborso: [inputRimborso, setInputRimborso] = [],
  inputNoRimborso: [inputNoRimborso, setInputNoRimborso] = [],
  flagAbilitazione: [flagAbilitazione, setFlagAbilitazione] = []
}) => {
  const selectedOption = selectedFilters[key]
  const optionsForDisplay = [
    ...options.filter((option) => !Object.values(selectedFilters)
      .some((selectedFilter) => selectedFilter.code === option.code)),
    selectedOption
  ]

  const removeFilter = (index) => {
    const newSelectedFilters = [...selectedFilters]
    newSelectedFilters.splice(index, 1)
    setSelectedFilters(newSelectedFilters)
  }

  const onSelectChange = (e, key) => {
    const newSelectedFilters = [...selectedFilters]
    newSelectedFilters[key] = e.value
    setSelectedFilters(newSelectedFilters)
  }

  const handleValueRefundableChange = (newValue, isRimborso) => {
    const newInputRimborso = [...inputRimborso]
    const newInputNoRimborso = [...inputNoRimborso]

    if (isRimborso) {
      newInputRimborso[key] = newValue
      newInputNoRimborso[key] = inputValues[key] - newValue
    } else {
      newInputNoRimborso[key] = newValue
      newInputRimborso[key] = inputValues[key] - newValue
    }

    setInputRimborso(newInputRimborso)
    setInputNoRimborso(newInputNoRimborso)
  }

  return (
    <Flex row fw js key={key} style={{ gap: 20 }}>
      <span className='p-float-label' style={{ marginTop: 15, width: '35%' }}>
        <Dropdown
          value={selectedOption}
          onChange={(e) => onSelectChange(e, key)}
          options={optionsForDisplay}
          optionLabel='name'
          placeholder='Aggiungi filtri'
          style={{ width: '100%', borderRadius: 13 }}
        />
        <label style={{ fontFamily: 'Lexend', fontSize: 14 }}>Tipo</label>
      </span>
      <span className='p-float-label' style={{ marginTop: 15 }}>
        <InputNumber
          className='inputNumbers'
          placeholder={selectedOption ? selectedOption.name : ''}
          inputId={`minmax-buttons-${value.code}`}
          value={inputValues[key]}
          onValueChange={(e) => {
            inputValues[key] = e.value
            inputRimborso[key] = e.value
            inputNoRimborso[key] = 0
            setInputValues([...inputValues])
            setInputRimborso([...inputRimborso])
            setInputNoRimborso([...inputNoRimborso])
          }}
          mode='decimal'
          showButtons
          min={0}
          max={50}
          style={{ marginTop: 5 }}
          inputStyle={{ width: '15%', borderTopLeftRadius: 13, borderBottomLeftRadius: 13, borderRight: '0px', height: 45, margin: 'auto' }}
          incrementButtonClassName='incrementNumberButton'
          decrementButtonClassName='decrementNumberButton'
        />
        <label style={{ fontFamily: 'Lexend', fontSize: 14 }}>N. proposte</label>
      </span>
      {name === 'Treno' && flagAbilitazione && (
        <>
          <span className='p-float-label' style={{ marginTop: 20 }}>
            <InputNumber
              className='inputNumbers'
              placeholder={selectedOption ? selectedOption.name : ''}
              inputId={`minmax-buttons-${value.code}`}
              value={inputRimborso[key]}
              onValueChange={(e) => handleValueRefundableChange(e.value, true)}
              mode='decimal'
              showButtons
              min={0}
              max={inputValues[key]}
              inputStyle={{ width: '15%', borderTopLeftRadius: 13, borderBottomLeftRadius: 13, borderRight: '0px', height: 45, margin: 'auto' }}
              incrementButtonClassName='incrementNumberButton'
              decrementButtonClassName='decrementNumberButton'
            />
            <label style={{ fontFamily: 'Lexend', fontSize: 14 }}>N. rimborsabili</label>
          </span>
          <span className='p-float-label' style={{ marginTop: 20 }}>
            <InputNumber
              className='inputNumbers'
              placeholder='N. non rimborsabili'
              inputId={`minmax-buttons-${value.code}`}
              value={inputNoRimborso[key]}
              onValueChange={(e) => handleValueRefundableChange(e.value, false)}
              mode='decimal'
              showButtons
              min={0}
              max={inputValues[key]}
              inputStyle={{ width: '15%', borderTopLeftRadius: 13, borderBottomLeftRadius: 13, borderRight: '0px', height: 45, margin: 'auto' }}
              incrementButtonClassName='incrementNumberButton'
              decrementButtonClassName='decrementNumberButton'
            />
            <label style={{ fontFamily: 'Lexend', fontSize: 14 }}>N. non rimborsabili</label>
          </span>
        </>
      )}
      <Flex width={40} row>
        <Button round icon='close' onClick={() => removeFilter(key)} />
      </Flex>
    </Flex>
  )
}
